import React from 'react';
import ForceGraphComponent from '../../pages/GraphPage/ForceGraphComponent';

const GraphPage = () => {
  return (
    <div className="graph-page">
      <ForceGraphComponent />
    </div>
  );
};

export default GraphPage;
